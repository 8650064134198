import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/Layout';
import dictionary from '../data/_dictionary';
import reviewers from '../data/_reviewers';

const Reviewers = ({
    pageContext: {description, from, lang, title, url},
}) => (
    <Layout
        description={description}
        lang={lang}
        name='reviewers'
        path={from}
        title={title}
        url={url}
    >
        <h2>{dictionary.reviewers[lang]}</h2>
        <ul>
            {reviewers.map(({sort, label}) => (
                <li key={sort}>
                    {label[lang]}
                </li>
            ))}
            </ul>

    </Layout>
);

export default Reviewers;
