import getLatinized from '../lib/getLatinized';

const reviewers = [
    {
         sort: 'Gaworski Ireneusz',
         label: {
            pl: 'dr hab. Ireneusz Gaworski (Uniwersytet Warszawski)',
            en: 'dr hab. Ireneusz Gaworski (Uniwersytet Warszawski)',
            de: 'Dr. habil. Ireneusz Gaworski (Uniwersytet Warszawski)'
        }
    },
    {
         sort: 'Grotek Monika',
         label: {
            pl: 'dr Monika Grotek (Uniwersytet Śląski)',
            en: 'dr Monika Grotek (Uniwersytet Śląski)',
            de: 'Dr. Monika Grotek (Uniwersytet Śląski)'
        }
    },
    {
         sort: 'Baran-Łucarz Małgorzata',
         label: {
            pl: 'dr Małgorzata Baran-Łucarz (Uniwersytet Wrocławski)',
            en: 'dr Małgorzata Baran-Łucarz (Uniwersytet Wrocławski)',
            de: 'Dr. Małgorzata Baran-Łucarz (Uniwersytet Wrocławski)'
        }
    },
    {
         sort: 'Jędrzejowski Łukasz',
         label: {
            pl: 'dr Łukasz Jędrzejowski (Universität zu Köln)',
            en: 'dr Łukasz Jędrzejowski (Universität zu Köln)',
            de: 'Dr. Łukasz Jędrzejowski (Universität zu Köln)'
        }
    },
    {
         sort: 'Just Anna',
         label: {
            pl: 'dr hab. Anna Just, prof. UW (Uniwersytet Warszawski)',
            en: 'prof. dr hab. Anna Just (Uniwersytet Warszawski)',
            de: 'Univ.-Prof. Dr. habil. Anna Just (Uniwersytet Warszawski)'
        }
    },
    {
         sort: 'Klockmann Heidi Elisabeth',
         label: {
            pl: 'prof. Heidi Elisabeth Klockmann (Universitetet i Agder)',
            en: 'prof. Heidi Elisabeth Klockmann (Universitetet i Agder)',
            de: 'Prof. Assoc. Heidi Elisabeth Klockmann (Universitetet i Agder)'
        }
    },
    {
         sort: 'Malak Janusz',
         label: {
            pl: 'dr hab. Janusz Malak, prof. UO (Uniwersytet Opolski)',
            en: 'dr hab. Janusz Malak, prof. UO (Uniwersytet Opolski)',
            de: 'Univ.-Prof. Dr. habil. Janusz Malak (Uniwersytet Opolski)'
        }
    },
    {
         sort: 'Morciniec Norbert',
         label: {
            pl: 'prof. Norbert Morciniec (Wrocław)',
            en: 'prof. Norbert Morciniec (Wrocław)',
            de: 'Prof. Dr. habil. Norbert Morciniec (Wrocław)'
        }
    },
    {
         sort: 'Ruda Marta',
         label: {
            pl: 'dr Marta Ruda (Uniwersytet Jagielloński w Krakowie)',
            en: 'dr Marta Ruda (Uniwersytet Jagielloński w Krakowie)',
            de: 'Dr. Marta Ruda (Uniwersytet Jagielloński w Krakowie)'
        }
    },
    {
         sort: 'Rytel-Schwarz Danuta',
         label: {
            pl: 'prof. Danuta Rytel-Schwarz (Universität Leipzig)',
            en: 'prof. Danuta Rytel-Schwarz (Universität Leipzig)',
            de: 'Univ.-Prof. Dr. habil. Danuta Rytel-Schwarz (Universität Leipzig)'
        }
    },
    {
         sort: 'Schatte Czesława',
         label: {
            pl: 'prof. Czesława Schatte (Poznań)',
            en: 'prof. Czesława Schatte (Poznań)',
            de: 'Univ.-Prof. Dr. habil. Czesława Schatte (Poznań)'
        }
    },
    {
         sort: 'Szczepaniak Jacek',
         label: {
            pl: 'dr hab. Jacek Szczepaniak, prof. ucz. (Uniwersytet Kazimierza Wielkiego w Bydgoszczy)',
            en: 'dr hab. Jacek Szczepaniak, prof. ucz. (Uniwersytet Kazimierza Wielkiego w Bydgoszczy)',
            de: 'Univ.-Prof. Dr. habil. Jacek Szczepaniak (Uniwersytet Kazimierza Wielkiego w Bydgoszczy)'
        }
    },
    {
         sort: 'Szczesniak Konrad',
         label: {
            pl: 'dr hab. Konrad Szczesniak, prof. UŚ (Uniwersytet Śląski)',
            en: 'dr hab. Konrad Szczesniak, prof. UŚ (Uniwersytet Śląski)',
            de: 'Univ.-Prof. Dr. habil. Konrad Szczesniak (Uniwersytet Śląski)'
        }
    },
    {
         sort: 'Taborek Janusz',
         label: {
            pl: 'prof. dr hab. Janusz Taborek (Uniwersytet im. Adama Mickiewicza w Poznaniu)',
            en: 'prof. dr hab. Janusz Taborek (Uniwersytet im. Adama Mickiewicza w Poznaniu)',
            de: 'Prof. Dr. habil. Janusz Taborek (Uniwersytet im. Adama Mickiewicza w Poznaniu)'
        }
    },
    {
         sort: 'Vinckel-Roisin Hélène',
         label: {
            pl: 'prof. dr hab. Hélène Vinckel-Roisin (Université de Lorraine)',
            en: 'prof. dr hab. Hélène Vinckel-Roisin (Université de Lorraine)',
            de: 'Prof. Dr. habil. Hélène Vinckel-Roisin (Université de Lorraine)'
        }
    },
    {
         sort: 'Woźny Jacek',
         label: {
            pl: 'dr Jacek Woźny (Uniwersytet Wrocławski)',
            en: 'dr Jacek Woźny (Uniwersytet Wrocławski)',
            de: 'Dr. Jacek Woźny (Uniwersytet Wrocławski)'
        }
    }
].sort((a, b) =>
    getLatinized((a.sort).toLowerCase()) >
    getLatinized((b.sort).toLowerCase()) ? 1 : -1
);

export default reviewers;
